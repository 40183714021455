import React, { useState } from "react";

import SectionLayout from "../SectionLayout";

import Logo from "./components/Logo";
import Navigation from "./components/Navigation";
import SignInSignUp from "./components/SignInSignUp";

import * as styles from "./index.module.scss";
import Hamburger from "./components/Hamburger";

const Header = () => {
  const [isActiveClass, setIsActiveClass] = useState(false);

  const toggleMobileMenu = () => {
    setIsActiveClass(!isActiveClass);
  };

  return (
    <SectionLayout className={styles.sectorWrapper}>
      <div className={styles.headerWrapper}>
        <Logo />
        <div
          className={
            isActiveClass
              ? styles.navMenu + " " + styles.menuActive
              : styles.navMenu + " " + styles.menuNotActive
          }
        >
          <Navigation />
          <SignInSignUp />
        </div>
        <Hamburger
          isActiveClass={isActiveClass}
          onToggleMobileMenu={toggleMobileMenu}
        />
      </div>
    </SectionLayout>
  );
};

export default Header;
